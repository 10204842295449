import React from 'react';
import LoginButton from './LoginButton';
import "../App.css";
import { useAuth0 } from "@auth0/auth0-react";
import LogoutButton from './LogoutButton.js';
import Logo from '../assets/logo.png'


const Background = () => {
  const {loginWithRedirect, isAuthenticated } = useAuth0();
  

  return (
    <div>
      <nav className="navbar navbar-dark bg-dark">
        <div className='container'>
            <a className="navbar-brand" href="#"><img src={Logo} className='logo'/></a>
            
          {!isAuthenticated ? <LoginButton /> : <LogoutButton />}
        </div>
      </nav>
      {!isAuthenticated && (
        <div className="main-page">
            <div className="main-page-bg">
              <h1 className='h1_bradhitc'>MGTV</h1>
              <h1>
              For Movies, Live Shows, Podcasts and more
              </h1>
              <h4>Watch anywhere. <br/>
              <button onClick={() => loginWithRedirect()} className="btn btn-danger banner_btn">Get Started  </button></h4>
              </div>    
        </div>
      )}
    </div>
  );
};

export default Background;
