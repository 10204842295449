import React from 'react';
import './App.css';
import VideoPlayer from './VideoPlayer';
import Background from './components/Background';
import { useAuth0 } from "@auth0/auth0-react";
import play2 from './assets/device-pile.png';
import Footer from './components/Footer';
import ReactGA from 'react-ga';

ReactGA.initialize('G-PKMS5FRD8Y'); // Replace with your tracking ID
ReactGA.pageview(window.location.pathname + window.location.search);

const App = () => {
  // Call the useAuth0 hook to get authentication methods and state
  const {  isAuthenticated } = useAuth0();

  return (
    <div className="App">
      <header className="App-header">
        <Background />
        {!isAuthenticated && (
          <div className="main_header">
            <div className='container'>
              <div className='row'>
              <div className='col-sm-6 hometext'>
                <h1>Watch Everywhere</h1>
                <h5>Stream films and Live Channels on your phone, tablet, laptop and TV.</h5>
                </div>
              <div className='col-sm-6'>
                <img src={play2} className='img-responsive  imgHome '/>
                </div>
                </div>
                
            </div>
           </div>
        )}
        <VideoPlayer />
      </header>
      <Footer/>
    </div>
  );
};

export default App;
