// Indicator.js
import React from 'react';
import "../App.css";

const Indicator = ({ netBitrate, mediaBitrate }) => {
    const alertClass = netBitrate < mediaBitrate ? 'alertcolour' : '';

    return (
        <div className={`indicator ${alertClass}`}></div>
    );
};

export default Indicator;
