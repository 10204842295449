// src/swDev.js

export default function swDev() {
  if ('serviceWorker' in navigator) {
    const swUrl = `${process.env.PUBLIC_URL}/sw.js`;

    navigator.serviceWorker.register(swUrl).then((registration) => {
      console.warn('Service Worker registered with scope:', registration.scope);
    }).catch((error) => {
      console.error('Service Worker registration failed:', error);
    });
  }
}
